import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaper } from '../../types/paper.type';
import { env } from '../../env';
import { sendProtectedRequest } from './sendProtectedRequest';

// Fetching paper by ID
export const getPaperById = createAsyncThunk<IPaper, number>(
  'get/getPaperById',
  async (id, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/papers/${id}`;
    try {
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch paper');
      }

      const data: IPaper = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
    }
  }
);

// Fetching paper by slug
export const getPaperBySlug = createAsyncThunk<
  IPaper,
  { yearMonth: number; slug: string; hash: number }
>(
  'get/getPaperBySlug',
  async ({ yearMonth, slug, hash }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/papers/${yearMonth}/${slug}/${hash}`;

    try {
      const response = await sendProtectedRequest(url, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch paper');
      }

      const data: IPaper = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
    }
  }
);

export const getPaperReferenceCitationById = createAsyncThunk<IPaper, number>(
  'get/getPaperReferenceCitationById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${env.apiUrl}/api/v1/papers/${id}/extended`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch paper');
      }

      const data: IPaper = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
    }
  }
);

export const addVoteToPaper = createAsyncThunk<
  { paperId: string | number; totalScore: number; alreadyVoted: boolean },
  number,
  { rejectValue: string }
>('post/addVoteToPaper', async (paperId, { rejectWithValue }) => {
  try {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/papers/${paperId}/votes`,
      {
        method: 'POST'
      }
    );
    if (!response.ok) {
      throw new Error('Failed to add vote');
    }

    const data = await response.json();
    return { paperId, ...data };
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : 'An unknown error occurred'
    );
  }
});

export const removeVoteFromPaper = createAsyncThunk<
  { paperId: string | number; totalScore: number; alreadyVoted: boolean },
  number,
  { rejectValue: string }
>('delete/removeVoteFromPaper', async (paperId, { rejectWithValue }) => {
  try {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/papers/${paperId}/votes`,
      {
        method: 'DELETE'
      }
    );
    if (!response.ok) {
      throw new Error('Failed to remove vote');
    }

    const data = await response.json();
    return { paperId, ...data };
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : 'An unknown error occurred'
    );
  }
});
