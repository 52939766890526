import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserPost } from '../../types/userPost.type';
import { env } from '../../env';
import { sendProtectedRequest } from './sendProtectedRequest';

interface GetUserPostArgs {
  id?: string;
}

export interface UpdateUserPostAdminArgs {
  id?: string;
  data: {
    title: string;
    markdown: string;
    topics: string[];
    communities: string[];
  };
}

export interface UpdateUserPostCreatorArgs {
  id?: string;
  data: {
    markdown: string;
    topics: string[];
    communities: string[];
  };
}

interface DeleteUserPostArgs {
  postId: number;
}

interface CreateUserPostArgs {
  title: string;
  markdown: string;
  topics: string[];
  communities: string[];
}

// Fetch User Post
export const getUserPost = createAsyncThunk<IUserPost, GetUserPostArgs>(
  'get/getUserPost',
  async ({ id }: GetUserPostArgs, { signal }) => {
    const response = await fetch(`${env.apiUrl}/api/v1/userposts/${id}`, {
      method: 'GET',
      signal
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the post');
    }

    if (!response.ok) {
      let errorMessage = '';
      if (response.status >= 400 && response.status < 500) {
        const errorData = await response.json();
        errorMessage = errorData.message || 'Failed to fetch the post.';
      } else if (response.status >= 500) {
        errorMessage =
          'Sorry, currently we can’t process your request. Please try again later.';
      }
      throw new Error(errorMessage);
    }

    const res = await response.json();

    return res as IUserPost;
  }
);

// Update Post by Creator
export const updateUserPostByCreator = createAsyncThunk(
  'post/updateUserPostByCreator',
  async ({ id, data }: UpdateUserPostCreatorArgs, {}) => {
    const url = `${env.apiUrl}/api/v1/userposts/${id}/by-creator`;

    const response = await sendProtectedRequest(url, {
      method: 'PUT',
      body: data
    });

    if (!response.ok) {
      throw new Error('Something went wrong with updating the post');
    }

    const updatedPost = await response.json();
    return updatedPost;
  }
);

// Update Post by Admin
export const updateUserPostByAdmin = createAsyncThunk(
  'post/updateUserPostByAdmin',
  async ({ id, data }: UpdateUserPostAdminArgs, {}) => {
    const url = `${env.apiUrl}/api/v1/userposts/${id}/by-super-user`;

    const response = await sendProtectedRequest(url, {
      method: 'PUT',
      body: data
    });

    if (!response.ok) {
      throw new Error('Something went wrong with updating the post');
    }

    const updatedPost = await response.json();
    return updatedPost;
  }
);

// Delete Post
export const deleteUserPost = createAsyncThunk(
  'post/deleteUserPost',
  async ({ postId }: DeleteUserPostArgs, {}) => {
    const url = `${env.apiUrl}/api/v1/userposts/${postId}/soft`;

    const response = await sendProtectedRequest(url, {
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new Error('Failed to delete the post');
    }
    // const res = await response.json();
    return postId;
  }
);

export const createUserPost = createAsyncThunk<
  IUserPost,
  CreateUserPostArgs,
  { rejectValue: string }
>('userPost/createUserPost', async (postData, { rejectWithValue }) => {
  try {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/userposts`,
      {
        method: 'POST',
        body: postData
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.message || 'Failed to create the post';
      throw new Error(errorMessage);
    }

    const data: IUserPost = await response.json();
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to create the post');
  }
});

export const addVoteToUserPost = createAsyncThunk<
  { userPostId: number; totalScore: number; alreadyVoted: boolean },
  number,
  { rejectValue: string }
>('post/addVoteToUserPost', async (userPostId, { rejectWithValue }) => {
  try {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/userposts/${userPostId}/votes`,
      { method: 'POST' }
    );
    if (!response.ok) throw new Error('Failed to vote');

    const data = await response.json();
    return { userPostId, ...data };
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : 'Unknown error'
    );
  }
});

export const removeVoteFromUserPost = createAsyncThunk<
  { userPostId: number; totalScore: number; alreadyVoted: boolean },
  number,
  { rejectValue: string }
>('delete/removeVoteFromUserPost', async (userPostId, { rejectWithValue }) => {
  try {
    const response = await sendProtectedRequest(
      `${env.apiUrl}/api/v1/userposts/${userPostId}/votes`,
      { method: 'DELETE' }
    );
    if (!response.ok) throw new Error('Failed to remove vote');

    const data = await response.json();
    return { userPostId, ...data };
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : 'Unknown error'
    );
  }
});
