import { useEffect } from 'react';
import { PaperHomeSortMethod } from '../../../utils/sortingConst';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectPapersHome,
  selectPapersHomeError,
  selectPapersHomeLoading
} from '../../../state/selectors/papersSelector';
import { getPapersHome } from '../../../state/actions/papersActions';
import { selectHomePaperFilterData } from '../../../state/selectors/filterSelector';

interface UseGetPapersHomeDataParams {
  page: number;
  pageSize: number;
  sortMethod: PaperHomeSortMethod;
}

export const useGetPapersHomeData = ({
  page,
  pageSize,
  sortMethod
}: UseGetPapersHomeDataParams) => {
  const dispatch = useAppDispatch();

  const papers = useAppSelector(selectPapersHome);
  const loading = useAppSelector(selectPapersHomeLoading);
  const error = useAppSelector(selectPapersHomeError);
  const filterData = useAppSelector(selectHomePaperFilterData);

  useEffect(() => {
    const controller = new AbortController();

    const fetchPapers = async () => {
      await dispatch(
        getPapersHome({
          page,
          pageSize,
          sortMethod,
          signal: controller.signal,
          filterData
        })
      );
    };

    fetchPapers();

    return () => {
      controller.abort();
    };
  }, [dispatch, page, pageSize, sortMethod, filterData]);

  return { papers, loading, error };
};
