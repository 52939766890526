import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICommentsResponse } from '../../types/comment.type';
import { env } from '../../env';
import { sendProtectedRequest } from './sendProtectedRequest';

// AsyncThunk for fetching comments without deleted comments
export const getComments = createAsyncThunk<
  ICommentsResponse,
  { discussionId: number; order?: string }
>('get/getComments', async ({ discussionId, order }, { rejectWithValue }) => {
  try {
    const url = `${env.apiUrl}/api/v1/discussions/${discussionId}?commentsOrder=${order || ''}`;
    const response = await sendProtectedRequest(url, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch comments');
    }

    const data = await response.json();
    return data as ICommentsResponse;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue('An error occurred while fetching the comments');
    }
  }
});

// AsyncThunk for posting a new comment
export const postCommentPaper = createAsyncThunk<
  ICommentsResponse,
  { papersId: number; body: string; parentCommentId: number | null }
>(
  'post/postCommentPaper',
  async ({ papersId, body, parentCommentId }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/papers/${papersId}/comments`;
      const response = await sendProtectedRequest(url, {
        method: 'POST',
        body: {
          body,
          parentCommentId
        }
      });

      if (!response.ok) {
        throw new Error('Failed to post comment');
      }
      const data = await response.json();
      return data as ICommentsResponse;
    } catch (error) {
      return rejectWithValue('Failed to post comment');
    }
  }
);

export const postCommentUserPost = createAsyncThunk<
  ICommentsResponse,
  { userPostId: number; body: string; parentCommentId: number | null }
>(
  'post/postCommentUserPost',
  async ({ userPostId, body, parentCommentId }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/userPosts/${userPostId}/comments`;
      const response = await sendProtectedRequest(url, {
        method: 'POST',
        body: {
          body,
          parentCommentId
        }
      });

      if (!response.ok) {
        throw new Error('Failed to post comment');
      }

      const data = await response.json();
      return data as ICommentsResponse;
    } catch (error) {
      return rejectWithValue('Failed to post comment');
    }
  }
);

// AsyncThunk for editing an existing comment
export const editCommentPapers = createAsyncThunk<
  void,
  { paperId: number; commentId: number; body: string }
>(
  'patch/editCommentPapers',
  async ({ paperId, commentId, body }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/papers/${paperId}/comments/${commentId}`;
      const response = await sendProtectedRequest(url, {
        method: 'PATCH',
        body: { body }
      });

      if (!response.ok) {
        throw new Error('Failed to edit comment');
      }
    } catch (error) {
      return rejectWithValue('Failed to edit comment');
    }
  }
);

export const editCommentUserPost = createAsyncThunk<
  void,
  { postId: number; commentId: number; body: string }
>(
  'patch/editComment',
  async ({ postId, commentId, body }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/userposts/${postId}/comments/${commentId}`;
      const response = await sendProtectedRequest(url, {
        method: 'PATCH',
        body: { body }
      });

      if (!response.ok) {
        throw new Error('Failed to edit comment');
      }
    } catch (error) {
      return rejectWithValue('Failed to edit comment');
    }
  }
);

// AsyncThunk for deleting an existing comment
export const deleteComment = createAsyncThunk<void, { commentId: number }>(
  'delete/deleteComment',
  async ({ commentId }, { rejectWithValue }) => {
    try {
      const url = `${env.apiUrl}/api/v1/comments/${commentId}/soft`;

      // Make a DELETE request using sendProtectedRequest
      const response = await sendProtectedRequest(url, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('Failed to delete comment');
      }
    } catch (error) {
      return rejectWithValue('Failed to delete comment');
    }
  }
);

// Add vote to comment
export const addVoteToComment = createAsyncThunk<
  { commentId: number; totalScore: number; alreadyVoted: boolean },
  number,
  { rejectValue: string }
>('post/addVoteToComment', async (commentId, { rejectWithValue }) => {
  try {
    const url = `${env.apiUrl}/api/v1/comments/${commentId}/votes`;
    const response = await sendProtectedRequest(url, { method: 'POST' });

    if (!response.ok) throw new Error('Failed to add vote');

    const data = await response.json();
    return { commentId, ...data };
  } catch {
    return rejectWithValue('Failed to add vote to comment');
  }
});

// Remove vote from comment
export const removeVoteFromComment = createAsyncThunk<
  { commentId: number; totalScore: number; alreadyVoted: boolean },
  number,
  { rejectValue: string }
>('delete/removeVoteFromComment', async (commentId, { rejectWithValue }) => {
  try {
    const url = `${env.apiUrl}/api/v1/comments/${commentId}/votes`;
    const response = await sendProtectedRequest(url, { method: 'DELETE' });

    if (!response.ok) throw new Error('Failed to remove vote');

    const data = await response.json();
    return { commentId, ...data };
  } catch {
    return rejectWithValue('Failed to remove vote from comment');
  }
});
