import { FC, useCallback, useState } from 'react';
import { useAuth } from '../../state/hooks/useAuth';
import { Tooltip } from '../core/tooltip';
import { VoteIcon } from '../../svgs/VoteIcon';
import { useNotifications } from '../notificationProvider/hooks/useNotifications';
import { useNavigate } from 'react-router-dom';
import {
  addVoteToPaper,
  removeVoteFromPaper
} from '../../state/actions/paperActions';
import { useAppDispatch } from '../../state';
import { VotedIcon } from '../../svgs/VotedIcon';
import { formatCount } from '../papersDiscussionRadio/hooks/usePaperCount';
import {
  addVoteToUserPost,
  removeVoteFromUserPost
} from '../../state/actions/userPostActions';
import {
  addVoteToComment,
  removeVoteFromComment
} from '../../state/actions/commentsActions';

interface VoteComponentProps {
  id: string | number;
  type: 'paper' | 'userPost' | 'comment';
  votes?: {
    alreadyVoted?: boolean;
    totalScore?: number;
  };
  onUpdateVotes?: (newScore: number, voted: boolean) => void;
  labelStyle?: string;
}

export const VoteComponent: FC<VoteComponentProps> = ({
  id,
  type,
  votes,
  labelStyle,
  onUpdateVotes
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const [isVoting, setIsVoting] = useState(false);
  const { notify } = useNotifications();
  const navigate = useNavigate();

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const handleVote = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!user) {
      handleOpenModalHash('signup');
      return;
    }

    setIsVoting(true);

    try {
      let result;
      if (votes?.alreadyVoted) {
        if (type === 'paper') {
          result = await dispatch(removeVoteFromPaper(Number(id))).unwrap();
        } else if (type === 'userPost') {
          result = await dispatch(removeVoteFromUserPost(Number(id))).unwrap();
        } else if (type === 'comment') {
          result = await dispatch(removeVoteFromComment(Number(id))).unwrap();
        }

        if (result) {
          onUpdateVotes?.(result.totalScore, false);
          notify('success', 'Vote removed successfully.');
        }
      } else {
        if (type === 'paper') {
          result = await dispatch(addVoteToPaper(Number(id))).unwrap();
        } else if (type === 'userPost') {
          result = await dispatch(addVoteToUserPost(Number(id))).unwrap();
        } else if (type === 'comment') {
          result = await dispatch(addVoteToComment(Number(id))).unwrap();
        }

        if (result) {
          onUpdateVotes?.(result.totalScore, true);
          notify('success', 'Vote added successfully.');
        }
      }
    } catch (error) {
      const message =
        error instanceof Error ? error.message : 'An error occurred.';
      notify('error', message);
    } finally {
      setIsVoting(false);
    }
  };

  return (
    <div className="itemActionComments itemActionVotes">
      <div className="flex">
        <Tooltip
          className="tooltipComments tooltipCitations"
          text={`Total Score: ${votes?.totalScore ?? '--'}`}
        >
          <button
            className="flex items-center transition-all actionVotes"
            onClick={handleVote}
            disabled={isVoting}
          >
            {votes?.alreadyVoted ? <VotedIcon /> : <VoteIcon />}
            <span className={labelStyle ? labelStyle : 'text-half'}>
              {votes?.totalScore && votes.totalScore > 0
                ? formatCount(votes.totalScore)
                : '0'}
            </span>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
