import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectPapers,
  selectPapersLoading,
  selectPapersError
} from '../../../state/selectors/papersSelector';
import { getPapersByCommunityUrlKey } from '../../../state/actions/papersActions';
import {
  resetCommunityPaperFilterData,
  resetTopicPaperFilterData
} from '../../../state/slices/filterPapersReducer';

interface UseGetPapersByCommunityUrlKeyData {
  page: number;
  pageSize: number;
  urlKey: string;
  sortMethod?: string;
}

export const useGetPapersByCommunityUrlKeyData = ({
  page,
  pageSize,
  urlKey,
  sortMethod
}: UseGetPapersByCommunityUrlKeyData) => {
  const dispatch = useAppDispatch();
  const papers = useAppSelector(selectPapers);
  const loading = useAppSelector(selectPapersLoading);
  const error = useAppSelector(selectPapersError);

  useEffect(() => {
    const controller = new AbortController();

    dispatch(resetTopicPaperFilterData());
    dispatch(resetCommunityPaperFilterData());
    const fetchPapers = async () => {
      await dispatch(
        getPapersByCommunityUrlKey({
          page,
          pageSize,
          urlKey,
          sortMethod,
          signal: controller.signal
        })
      );
    };

    fetchPapers();

    return () => {
      controller.abort();
    };
  }, [dispatch, page, pageSize, urlKey, sortMethod]);

  return { papers, loading, error };
};
